import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../layouts/index"

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div className="content-wrap">
        <div className="blog-content-top">
          <Link to="/blog">最新资讯</Link>
          <p>
            作者：{post.frontmatter.author}
            <span>发布日期：{post.frontmatter.date}</span>
          </p>
        </div>
        <div className="blog-content">
          <h1>{post.frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
          <div className="blog-content-btm">
            <Link to="/blog">返回最新资讯</Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        author
      }
    }
  }
`
